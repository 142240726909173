import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import api from "../../../api/api";
import logo from "../../../assets/images/ciwa.png";
import useDetails from "../../../hooks/useDetails";
import { useReactToPrint } from "react-to-print";
import { getCurrentUser } from "../../../utils/authService";
import { GetStudentDetails } from "../../../api/student";
const CourseRegPrintout = () => {
  const location = useLocation();
  const [validation, setValidation] = useState();
  const [level, setLevel] = useState("");
  const [courses, setCourses] = useState();
  const { data: student } = useDetails();
  const user = getCurrentUser();

  const firstSemesterCourses = courses?.filter(
    (course) => course.semester.toLowerCase() === "first"
  );
  const secondSemesterCourses = courses?.filter(
    (course) => course.semester.toLowerCase() === "second"
  );

  const firstSemesterTotalUnits = firstSemesterCourses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );
  const secondSemesterTotalUnits = secondSemesterCourses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const totalUnits = courses?.reduce(
    (acc, course) => acc + parseInt(course.unit),
    0
  );

  const getDetails = async () => {
    try {
      const data = await GetStudentDetails(user);
      setLevel(data?.level);
    } catch (error) {}
  };

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 6rem 8rem;
  }
`;
  const printCourseReg = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Course Reg",
    pageStyle: pageStyle,
  });

  useEffect(() => {
    const validated = async () => {
      try {
        const { data } = await api.get(`/courses/validated/first/${level}`);
        setValidation(data?.data[0]);
      } catch (error) {}
    };

    validated();
  }, [student, level]);

  const getRegisteredCourses = async () => {
    try {
      const { data } = await api.get(`/courses/student-view`);
      setCourses(data);
    } catch (error) {}
  };

  useEffect(() => {
    getRegisteredCourses();
    getDetails();
  }, []);

  // if (validation?.is_validated === false) {
  //   return (
  //     <Navigate
  //       to={{
  //         pathname: `/course-reg`,
  //         state: { from: location },
  //       }}
  //       replace
  //     />
  //   );
  // }

  return (
    <div className="page-wrapper">
      <div className="page-content-tab">
        <div className="container-fluid">
          <div className="card" ref={componentRef}>
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">Catholic Institute of West Africa</h3>
              </div>
              <h4 className="subheader">Course Registration Printout</h4>
            </div>
            <div className="p-5">
              {firstSemesterCourses?.length > 0 &&
              <>
              <table style={{ fontSize: "16px", marginBottom: "3rem" }}>
                <tbody>
                  <tr>
                    <th rowSpan={7}>
                      <img
                        alt="passport"
                        src={student?.photo}
                        style={{ width: "140px" }}
                      />
                    </th>
                  </tr>
                  <tr>
                    <th>Name</th>
                    <td>{student?.surname + " " + student?.othername}</td>
                  </tr>
                  <tr>
                    <th>Matriculation Number</th>
                    <td>{student?.matric_no}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>{student?.department}</td>
                  </tr>
                  <tr>
                    <th>School/Faculty</th>
                    <td>{student?.faculty}</td>
                  </tr>
                  <tr>
                    <th>Programme</th>
                    <td>{student?.programme}</td>
                  </tr>
                  <tr>
                    <th>Semester</th>
                    <td>1st semester</td>
                  </tr>
                </tbody>
              </table>
              <table
                className="w-100"
                style={{ fontSize: "16px", marginBottom: "3rem" }}
              >
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Course</th>
                    <th>Unit</th>
                    <th>Semester</th>
                  </tr>
                </thead>
                <tbody>
                  {firstSemesterCourses?.map(
                    ({ id, code, semester, title, unit }) => (
                      <tr key={id}>
                        <td style={{ fontWeight: "700" }}>{code}</td>
                        <td style={{ fontWeight: "700" }}>{title}</td>
                        <td style={{ fontWeight: "700" }}>{unit}</td>
                        <td style={{ fontWeight: "700" }}>{semester}</td>
                      </tr>
                    )
                  )}
                  <tr>
                    <td></td>
                    <td></td>
                    <th style={{ fontWeight: "700", fontSize: "26px" }}>
                      {firstSemesterTotalUnits}
                    </th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <div className="px-5" style={{ marginBottom: "3rem" }}>
                <table style={{ borderCollapse: "collapse", border: "none" }}>
                  <thead style={{ border: "none" }}>
                    <td style={{ border: "none", width: "15em" }}></td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Name
                    </td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Rank
                    </td>
                    <td
                      style={{
                        border: "none",
                        textAlign: "center",
                        width: "10em",
                      }}
                    >
                      Signature
                    </td>
                    <td style={{ border: "none", textAlign: "center" }}>
                      Date
                    </td>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: "none" }}>Head of Department</td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none" }}>Dean of Faculty</td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="text-center">(FOR MASTER'S/PHD THESIS)</h4>
                <table style={{ borderCollapse: "collapse", border: "none" }}>
                  <tbody>
                    <tr>
                      <td style={{ border: "none", width: "15em" }}>
                        Chief Supervisor
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none", width: "15em" }}>
                        Second Supervisor
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ border: "none", width: "15em" }}>
                        Third Supervisor
                      </td>
                      <td style={{ border: "none" }}>
                        <div className="line2 mt-4"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </>
}

              {secondSemesterCourses?.length > 0 && (
                <>
                  <div
                    style={{
                      pageBreakBefore:
                        firstSemesterCourses?.length > 0 ? "always" : "",
                    }}
                  ></div>
                  <table style={{ fontSize: "16px", marginBottom: "3rem" }}>
                    <tbody>
                      <tr>
                        <th rowSpan={7}>
                          <img
                            alt="passport"
                            src={student?.photo}
                            style={{ width: "140px" }}
                          />
                        </th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>{student?.surname + " " + student?.othername}</td>
                      </tr>
                      <tr>
                        <th>Matriculation Number</th>
                        <td>{student?.matric_no}</td>
                      </tr>
                      <tr>
                        <th>Department</th>
                        <td>{student?.department}</td>
                      </tr>
                      <tr>
                        <th>School/Faculty</th>
                        <td>{student?.faculty}</td>
                      </tr>
                      <tr>
                        <th>Programme</th>
                        <td>{student?.programme}</td>
                      </tr>
                      <tr>
                        <th>Semester</th>
                        <td>2nd semester</td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="w-100" style={{ fontSize: "16px" }}>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Course</th>
                        <th>Unit</th>
                        <th>Semester</th>
                      </tr>
                    </thead>
                    {secondSemesterCourses?.map(
                      ({ id, code, semester, title, unit }) => (
                        <tr key={id}>
                          <td style={{ fontWeight: "700" }}>{code}</td>
                          <td style={{ fontWeight: "700" }}>{title}</td>
                          <td width={"7%"} style={{ fontWeight: "700" }}>
                            {unit}
                          </td>
                          <td style={{ fontWeight: "700" }}>{semester}</td>
                        </tr>
                      )
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <th style={{ fontWeight: "700", fontSize: "26px" }}>
                        {secondSemesterTotalUnits}
                      </th>
                      <td></td>
                    </tr>
                  </table>

                  <div className="px-5">
                    <table
                      style={{ borderCollapse: "collapse", border: "none" }}
                    >
                      <thead style={{ border: "none" }}>
                        <td style={{ border: "none", width: "15em" }}></td>
                        <td style={{ border: "none", textAlign: "center" }}>
                          Name
                        </td>
                        <td style={{ border: "none", textAlign: "center" }}>
                          Rank
                        </td>
                        <td
                          style={{
                            border: "none",
                            textAlign: "center",
                            width: "10em",
                          }}
                        >
                          Signature
                        </td>
                        <td style={{ border: "none", textAlign: "center" }}>
                          Date
                        </td>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ border: "none" }}>Head of Department</td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "none" }}>Dean of Faculty</td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h4 className="text-center">(FOR MASTER'S/PHD THESIS)</h4>
                    <table
                      style={{ borderCollapse: "collapse", border: "none" }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ border: "none", width: "15em" }}>
                            Chief Supervisor
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "none", width: "15em" }}>
                            Second Supervisor
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ border: "none", width: "15em" }}>
                            Third Supervisor
                          </td>
                          <td style={{ border: "none" }}>
                            <div className="line2 mt-4"></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="card"></div>
          <div className="card">
            <button className="btn dspg-danger" onClick={printCourseReg}>
              Print file
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseRegPrintout;
