export let URL = process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_LOCAL_URL : (process.env.REACT_APP_ENV === 'staging' ? process.env.REACT_APP_STAGING_URL : (process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PRODUCTION_URL : ''));

// export let auth_token = localStorage.getItem('student-token');

export const asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  };

  export const auth_token = () => {
    return localStorage.getItem('student-token') 
  }

  export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  export const paymentOptions = [
    {
      name: "School Fees - Full Payment",
      value:'full'
    },
    {
      name: "School Fees - Part Payment",
      value:'part'
    },
    {
      name: "Late Payment",
      value:'late'
    },
  ]

  export const OtherPaymentOptions = [
    {
      name: "SICT I",
      value: "SICT I",
    },
    {
      name: "ACCT",
      value: "ACCT",
    },
    {
      name: "SUG",
      value: "SUG",
    },
    
  ]